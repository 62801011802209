<template>
  <div class="footer-wrapper">
    <div v-if="isMobile"
         class="flex">
      <NuxtLink :to="faceBookUrl"
                target="_blank">
        <svg-icon
            class="mr-[8px] cursor-pointer"
            name="fb"
            size="30"
        />
      </NuxtLink>
      <NuxtLink :to="lineUrl"
                target="_blank">
        <svg-icon
            class="cursor-pointer"
            name="line"
            size="30"
        />
      </NuxtLink>
    </div>
    <div>
      <NuxtLink to="/" class="cursor-pointer ml-5 mb-[30px] flex justify-center">
        <NuxtImg format="webp"
                 quality="80"
                 loading="lazy"
                 width="200"
                 src="/logo/osn-zh-horizon.png"
                 alt="logo"/>
      </NuxtLink>

      <ul class="sub-menu">
        <li>
          <NuxtLink to="/host/homepage"
                    target="_blank">
            {{ $t('menu.function_introduction') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/host/plan"
                    target="_blank">
            {{ $t('menu.owner_index') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/host/qa"
                    target="_blank">
            {{ $t('menu.help_center') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="lineUrl"
                    target="_blank">
            {{ $t('menu.line') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="privacyUrl"
                    target="_blank">
            {{ $t('menu.privacy_policy') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="clientUrl"
                    target="_blank">
            {{ $t('menu.contract_terms') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="hostTermsUrl"
                    target="_blank">
            {{ $t('menu.host_terms') }}
          </NuxtLink>
        </li>
      </ul>
      <ul class="company">
        <li>Copyright © 2024 One Step Technology Limited Company. All Rights Reserved.</li>
        <li>一步科技有限公司 統一編號：90041170</li>
      </ul>
    </div>
    <div v-if="!isMobile"
         class="flex">
      <NuxtLink :to="faceBookUrl"
                target="_blank">
        <svg-icon
            class="mr-[8px] cursor-pointer"
            name="fb"
            size="30"
        />
      </NuxtLink>
      <NuxtLink :to="lineUrl"
                target="_blank">
        <svg-icon
            class="cursor-pointer"
            name="line"
            size="30"
        />
      </NuxtLink>
    </div>

  </div>
</template>
<script setup>
import {computed, ref} from 'vue'

const settingStore = useSettingStore()
const isMobile = computed(() => settingStore.isMobile);

const helpUrl = ref(import.meta.env.VITE_APP_BASE_HELP_URL)
const lineUrl = ref(import.meta.env.VITE_APP_BASE_LINE_URL)
const clientUrl = ref(import.meta.env.VITE_APP_BASE_CLIENT_URL)
const hostTermsUrl = ref(import.meta.env.VITE_APP_BASE_TERMS_URL)
const privacyUrl = ref(import.meta.env.VITE_APP_BASE_PRIVACY_URL)
const faceBookUrl = ref(import.meta.env.VITE_APP_BASE_FACEBOOK_URL)

</script>

<style lang="scss"
       scoped>
.footer-wrapper {
  @apply flex border-t
  pc:pt-[50px] pc:pb-[45px] pc:justify-around
  mobile:mobile:mb-[25px] mobile:flex-col mobile:items-center mobile:justify-center;

  > div {
    @apply mt-[20px];
  }

  ul.sub-menu {
    @apply flex
    pc:justify-between
    mobile:px-[30px] mobile:flex-wrap mobile:justify-start mobile:text-center;

    li {
      @apply pc:mr-3 mobile:mb-[20px] mobile:w-1/3;
    }
  }

  ul.company {
    @apply text-[12px] text-osn-dark-grey-43
    pc:mt-[19px];
    li {
      @apply mb-[8px]
      mobile:px-[35px] mobile:text-center;
    }

    li:last-child {
      @apply m-0;
    }
  }

}
</style>
